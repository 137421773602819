import React from 'react'
import { FaBookOpen } from "react-icons/fa6";
import { BsPersonVideo } from "react-icons/bs";
import { AiOutlineSend } from "react-icons/ai";
import { RiPagesLine } from "react-icons/ri";
import { CiVideoOn } from "react-icons/ci";
import Card from './Card';
import Banner from '../asset/icons/Banner.jpeg'
import { motion } from 'framer-motion';
import './Course.scss';
const Course = () => {
  const fadeIn = {
    hidden: { opacity: 0, y: -50 },
   visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
   };
const cardData = {
    title: " Complete Frontend Development Course (Projects + Roadmap)",
    current:"₹3,000",
    prev:"₹6,999",
    button:"Buy Course",
    course:"COURSE INCLUDES :",
    point1:"No prerequisite Required",
    point2:" Best Projects",
    point3:"Alternate days classes",
    point4:"Sunday Doubt classes",
    point5:"Live Sessions",
    icon:<AiOutlineSend  className="point-icon"/>,
    image: Banner,
    type:true,
    card:true,
    link:"https://pages.razorpay.com/webshef1"
  };
  
  return (
    <motion.div variants={fadeIn}
    initial="hidden"
    whileInView="visible"
    viewport={{ once: true }}className="front-section">
        <div className="frontpart">
          {/*  front right part */}

          <div className="right-front">
            <p className="front-heading">6 Month To Launch Your Career 🔥</p>

            <h1 className="front-main__heading">
              WebShef <span style={{ color: "orange" }}>2.O</span>
            </h1>

            <p className="front-last__heading">
              The Most Affordable Programme For Front-End Mastery
            </p>
<p className="front-mini-heading">3 Month Extensive Training + 3 Month Internship</p>

            <div className="time-grid">
<div className="from-time__grid"><div className="icon"><CiVideoOn  className="time-icon"/></div><p>Live Classes</p></div>
<div className="from-time__grid-seclast"><div className="icon"><RiPagesLine  className="time-icon2"/></div><p>Projects</p></div>
<div className="from-time__grid"><div className="icon"><BsPersonVideo  className="time-icon3"/></div><p>Doubt classes</p></div>
<div className="from-time__grid-last"><div className="icon"><FaBookOpen className="time-icon4" /></div><p>Assignment</p></div>
            </div>

            <div className="start_batch" style={{ marginTop:"4rem"}}>
              <h6 style={{fontSize:"1.5rem" , color:"gray" ,fontWeight:"400" }} >Start 2.0 on 1 February 2025</h6>
            </div>
          </div>

          {/*  front left part */}
        <Card 
          title={cardData.title}
          current={cardData.current}
          prev={cardData.prev}
          button={cardData.button}
          course={cardData.course}
          point1={cardData.point1}
          point2={cardData.point2}
          point3={cardData.point3}
          point4={cardData.point4}
          point5={cardData.point5}
          icon={cardData.icon}
          image={cardData.image}
          type={cardData.type}
          card={cardData.card}
          link={cardData.link}
        />
          
        </div>
      </motion.div>


  )
}

export default Course

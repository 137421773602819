import React from 'react'
import Banner from '../asset/icons/Banner.jpeg'
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import safiya4 from '../asset/icons/safiya4.png'
import './Livecourse.scss'
const Livecourse = () => {


  const data=[
    {
      id:1,
      mainheading:"Master web dev-Webshef -1.0",
      secheading:"6 month program to launch your carrier as web dev",
     
      image:Banner,
      url:"/course",
     date:" Start : 1 February 2025",
      button:"Enroll now"
    },

   

   
  ]

  const[seminar ,setSeminar]=useState(data)
  return (
    <div className='livecourse-container'>
      <div className="livecourse">
        <div className="livecourse-heading"><h1 className="heading">Live Courses </h1></div>
        <div className="livecourse_live-workshop">
       {
        seminar.map((e)=>{
          return(
            < div key={e.id}>
            <div className="livecourse_live-section">
            <img src={e.image} alt="" className="livecourse_workshop-img" />
            <h4 className='livecourse_live-lastmain__heading'>{e.mainheading} </h4>
            <h4 className='livecourse_live-last__heading'>{e.secheading}</h4>
            <h4 className='livecourse_live-last__heading'>{e.date}</h4>

            <div className="livecourse_workshop-lower__section">
            
               <Link to={e.url}> <button className="livecourse_live-btn">{e.button}</button></Link>
            </div>
          
        </div>

            </div>
          )
        })
       }
    
        
      </div>


      </div>

      
    </div>
  )
}

export default Livecourse;

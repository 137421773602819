import React, { useState } from "react";
import './FAQ.scss'
const FAQ = () => {
  const faqs = [
    { question: "Will React be covered in this course?", answer: "Yes, we will cover all React concepts in this course." },
    { question: "What are the prerequisites for this course?", answer: "A laptop with internet connection and a hunger for learning." },
    { question: "What language will the course be taught in?", answer: "A mix of Hindi and English." },
    { question: "Is the course recorded or live?", answer: "The course will be live, and recorded lectures will be provided." },
    { question: "Will you cover Node.js?", answer: "This is a frontend-focused React course, so Node.js won't be covered." },
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="faq-container">
      {faqs.map((faq, index) => (
        <div key={index} className="faq-item">
          <div className="faq-question" onClick={() => toggleFAQ(index)}>
            <p>{faq.question}</p>
            <span className="faq-icon">{activeIndex === index ? "-" : "+"}</span>
          </div>
          <div className={`faq-answer ${activeIndex === index ? "open" : ""}`}>
            <p className="answer">{faq.answer}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FAQ;

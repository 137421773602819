import React, { useState } from 'react';
import './Homepage.scss';
import animationData from '../asset/animation.json'
import Lottie from 'lottie-react';
import  { useEffect } from "react";
import compressazim from '../asset/icons/compressazim.jpeg'
import { FaLinkedin } from "react-icons/fa6";

import EmployeeSlider from '../Sliders/EmployeeSlider';
import { motion } from 'framer-motion';

import { Link } from 'react-router-dom';

import SessionSlider from '../Sliders/SessionSlider';

import { useNavigate } from 'react-router-dom';
import Workshop from '../Workshop/Workshop';
import Navbar from '../Common/Navbar';

import Contact from '../Common/Contact';

import Footer from '../Common/Footer';

import Livecourse from '../Livecourse/Livecourse';
export default function Homepage() {
  // const { isLoggedIn } = useContext(LogContext);
 
  const [openQuestion, setOpenQuestion] = useState(null);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const[isHelp ,setIsHelp]=useState(false)

  
  const toggleQuestion = (index) => {
    setOpenQuestion(openQuestion === index ? null : index);
  };

  const toggleTermsModal = () => {
    setIsTermsOpen(!isTermsOpen);
   
// setIsHelp(!isHelp)
  };


  const togglePrivacyModel=()=>{
    setIsPrivacy(!isPrivacy)
  }


  const togglecontactModel=()=>{
    setIsHelp(!isHelp)
  }


  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);


  const navigate = useNavigate();

  const fadeIn = {
   hidden: { opacity: 0, y: -50 },
  visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
  };

  const firstTextArray = [
    
    
    "REACT",
    "ANDROID",
    "FUNCTION",
    "JAVASCRIPT",
    "CTRL"
  ];

  const secondTextArray = ["HOOKS", "LOGICS", "SYNTAX", "DOM", "SAVE"];

  const [firstText, setFirstText] = useState(firstTextArray[0]);
  const [secondText, setSecondText] = useState(secondTextArray[0]);
  const [isVisible, setIsVisible] = useState(true); // V
  const [isBlurred, setIsBlurred] = useState(false);
  let firstIndex = 0;
  let secondIndex = 0;

  useEffect(() => {
    const interval = setInterval(() => {
      // Trigger fade-out
      setIsVisible(false);
      setIsBlurred(true);

      setTimeout(() => {
        // Update text after fade-out
        firstIndex = (firstIndex + 1) % firstTextArray.length;
        setFirstText(firstTextArray[firstIndex]);

        secondIndex = (secondIndex + 1) % secondTextArray.length;
        setSecondText(secondTextArray[secondIndex]);

        // Trigger fade-in
        setIsBlurred(false);

        setIsVisible(true);
      }, 1000); // Match this duration with the CSS transition
    }, 3000); // Change every 2 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className='Home-container'>
      <Navbar togglecontactModel={togglecontactModel}
     
      />

      {/* Render the Contact Modal */}
      {isHelp && (
        <Contact togglecontactModel={togglecontactModel}/>
      )}

      {/* front main part */}
      <motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="main_front-section">
        <div className="main_frontpart">
          <div className="main_right-front">
            <h1 className="main_front-main__heading">
            <span> CTRL + CREATIVITY</span>
            </h1>
          
            <p className="main_front-last__heading">
            Let’s Create a Powerful <br /> Recipe <span className="highlight blur " >{firstText}</span> with {" "}
        <strong className="highlight">{secondText} </strong>
            </p>
            <Link to="/course" style={{ textDecoration: "none", color: "white" }}>
            <motion.button
              className="btn-joinnow"
              whileHover={{ scale: 1.1 }}
             
              transition={{
                duration: 2, 
               
                ease: "easeInOut"
              }}
              style={{ marginTop: "2rem" }}
            >
             
              Explore Now
            </motion.button>
            </Link>
          </div>

          <div className="main_left-front">
            <div className="main_left-main__card-sec">
          
            <Lottie  className='lottie'
        animationData={animationData} 
        loop={true} 
       
      />

            </div>

          </div>
        </div>
      </motion.div>

{/*  course */}

<motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="course_section">
  <div className="course">
   <Livecourse/>
  </div>
</motion.div>


      {/*  Live workshops */}

    <div className="workshop_card--section">
      <motion.div  variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="work_card">
      <Workshop/>
      </motion.div>
    </div>

      {/* Screenshot autoslider */}
      <div className="auto-slider">
        <motion.div  variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="slider">
          <div className="slider-heading">
            <h1 className="heading">What Students Love About Us?</h1>
          </div>
          <div className="slide-section">
            <EmployeeSlider />
          </div>
        </motion.div>
      </div>

      {/* Mentor section */}
      <motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="Mentor-section">
        <div className="Mentorpart">
          <div className="mentor_right-front">
            <h1 className="mentor-main__heading">
              Connect with us for Personal Guidance
            </h1>
            <p className="tagline">
        Elevate your career with 1:1 mentorship from industry experts.
      </p>
      <ul className="benefits-list">
      
        <li> Personalized career advice tailored to your goals</li>
        <li> Resume and portfolio reviews by professionals</li>
        <li> Insights into the latest tech and industry trends</li>
       
      </ul>
            
         <div className="mentor_buttons">
         <a href="https://topmate.io/azim_sidd"> <button className="btn-join">Book Your 1:1 Session Now</button></a>
         </div>
          </div>
          <div className="mentor_left-front">
            <div className="card-mentor">
             <div className="card-mentor--img">
             <img className="image-mentor" src={compressazim} alt="" />
              
             </div>
              <div className="description"><p>Azim Siddiqui</p>
              <p> Senior Softwere Engineer</p>
            <div className="linkedin-div">
            <a href="https://www.linkedin.com/in/azimsidd/"><FaLinkedin  className='linkedin' style={{fontSize:"2rem" , marginTop:".7rem",color:"orange"}}/></a> 
            </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* top mate session slider */}
      <motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="session-slider">
        <div className="session">

        <div className="session-heading">
       <h1 className="heading" style={{marginTop:"8rem"}}>1:1 Session Reviews</h1>
           
          </div>
       
          <div className="session-slider__container">
            <SessionSlider />
          </div>
        </div>
      </motion.div>

      {/* Footer section */}
  <div className="footer_last--section">
    <div className="last_section">
    <Footer/>
    </div>
  </div>



    </div>
  );
}

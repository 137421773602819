import React from 'react'
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Privacy from './Privacy';
import Model from './Model';
import Contact from './Contact';
import './Footer.scss';
const Footer = () => {
    const [openQuestion, setOpenQuestion] = useState(null);
    const [isTermsOpen, setIsTermsOpen] = useState(false);
    const [isPrivacy, setIsPrivacy] = useState(false);
    const[isHelp ,setIsHelp]=useState(false)
  
    
   
  
    const toggleTermsModal = () => {
      setIsTermsOpen(!isTermsOpen);
     
  // setIsHelp(!isHelp)
    };
  
  
    const togglePrivacyModel=()=>{
      setIsPrivacy(!isPrivacy)
    }
  
  
    const togglecontactModel=()=>{
      setIsHelp(!isHelp)
    }
  
  
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  
   
    const navigate = useNavigate();
  return (
    <div>
       <div className="footer-section">
        <div className="footer">
          <div className="footer-items">
            <li className='footer-items__list' onClick={toggleTermsModal}>Terms of Service</li>
            <li className='footer-items__list'  onClick={togglePrivacyModel}>Privacy Policy</li>
            <li className='footer-items__list'  onClick={ togglecontactModel}>Contact</li>
          </div>
        </div>
        <h3 className="footer-main__heading">@TheCodingshef</h3>
        <p className='copyright'>Copyright © 2024</p>
      </div>

      {/* Terms of Service Modal */}
      {isPrivacy && (
        <Privacy togglePrivacyModel={togglePrivacyModel}/>
)}

{isTermsOpen && (
  <Model toggleTermsModal={toggleTermsModal}/>
)}

{isHelp && (
  <Contact  togglecontactModel={togglecontactModel}/>
)}

    </div>
  )
}

export default Footer

import React from 'react'
import './Workshop.scss';
import { useState } from 'react';

import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import banner2 from '../asset/icons/WhatsApp Image 2024-12-19 at 10.45.57 AM.jpeg'
import anfiya2 from '../asset/icons/anfiya2.jpg'
import sofiya from '../asset/icons/sofiya.png';
import sofiya2 from '../asset/icons/sofiya2.png';

const Workshop = () => {


  const data=[
    {
      id:1,
      mainheading:"Kickstart your Front-End Journey with React",
      secheading:"Sunday 21 Nov-2024",
      time:"Time : 7:00 PM",
      image:sofiya2,
      url:"",
      live:"Completed",
      button:"Expired"
    },

    {
      id:2,
      mainheading:"Let’s build with React and bring your vision to life!",
      secheading:"Sunday 8 Dec-2024",
      time:"Time : 7:00 PM",
      image:sofiya,
      url:"",
      live:"Completed",
      button:"Expired"
    },
    {
      id:3,
      mainheading:"Crack you next android Interview 3.O",
      secheading:"Saturday 12 January-2025",
      time:"Time : 4:00 PM - 5:00 PM",
      image:banner2,
      url:"/android-workshop",
      live:"Live",
     button:"Book your slot"
    },

    {
      id:4,
      mainheading:"Build your portfolio using React Js",
      secheading:"Saturday 11 Jan-2024",
      time:"Time : 5:00 PM - 8:00 PM",
      image:anfiya2,
      url:"/react-master-class",
    live:"Live",
      button:"Book your slot"
    },

   
  ]

  const[seminar ,setSeminar]=useState(data)
  return (
    <div className='workshop-container'>
      <div className="workshop">
        <div className="workshop-heading"><h1 className="heading">Live Workshops</h1></div>
        <div className="live-workshop">
       {
        seminar.map((e)=>{
          return(
            <div key={e.id}>
            <div className="live-section">
            <img src={e.image} alt="" className="workshop-img" />
            <h4 className='live-lastfirst__heading'>{e.mainheading} </h4>
            <h4 className='live-last__heading'>{e.secheading}</h4>
            <h4 className='live-last__heading'>{e.time}</h4>
            <div className="workshop-lower__section">
                <h3 className='live-heading'> {e.live}<motion.span
                        animate={{ scale: [1, 1.2, 1] }} // Pulsing effect
                        transition={{
                            duration: 1, // Duration of one cycle
                            repeat: Infinity, // Infinite looping
                            ease: "easeInOut" // Smooth transitions
                        }}
                        style={{ display: 'inline-block' }} // Keeps the emoji inline
                    >
                   
                    </motion.span></h3>
                    <button className="live-btn"> <Link to={e.url} style={{color:"white" , fontSize:"1.2rem"}}>{e.button}
               
               </Link></button>
               
            </div>
          
        </div>

            </div>
          )
        })
       }
        {/* <div className="live-section">
            <img src={Banner} alt="" className="workshop-img" 
            <h4 className='live-last__heading'>Live Workshop on Sunday 17Nov-2024</h4>
            <div className="workshop-lower__section">
                <h3 className='live-heading'>Live</h3>
                <Link to='/workshop'> <button className="live-btn">Register</button></Link>
            </div>
          
        </div> */}
        
      </div>


      </div>

      
    </div>
  )
}

export default Workshop

import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom'; // Import BrowserRouter directly
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Importing components
import Androidpage from './Workshop/Androidpage';
import Main from './Components/Main';
import Homepage from './Components/Homepage';
import Signup from './Login/Signup';
import LoginForm from './Login/LoginForm';
import Workshoppage from './Workshop/Workshoppage';
import Reactworkshop from './Workshop/Reactworkshop';
import Paidworkshop from './Workshop/Paidworkshop';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <BrowserRouter
      future={{
        v7_startTransition: true,
        v7_relativeSplatPath: true,
      }}
    >
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/course" element={<Main />} />
        <Route path="/home" element={<Homepage />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/react-workshop" element={<Workshoppage />} />
        <Route path="/sec-react-workshop" element={<Reactworkshop />} />
        <Route path="/android-workshop" element={<Androidpage />} />
        <Route path="/react-master-class" element={<Paidworkshop />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// Log performance measurements
reportWebVitals();

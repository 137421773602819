

import React, { useState, useEffect } from 'react';

const Timer = ({timer}) => {
    const storedTime = parseInt(localStorage.getItem('timeLeft'), 10);
    const initialTime = storedTime || 3 * 60 * 60; // 3 hours in seconds
    const [timeLeft, setTimeLeft] = useState(initialTime);
  
    useEffect(() => {
      const timer = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            localStorage.setItem('timeLeft', 3 * 60 * 60); // Reset time in localStorage
            return 3 * 60 * 60; // Reset the timer to 3 hours
          }
          const newTime = prevTime - 1;
          localStorage.setItem('timeLeft', newTime); // Save the updated time to localStorage
          return newTime;
        });
      }, 1000);
  
      return () => clearInterval(timer); // Cleanup the interval on component unmount
    }, []);
  
    const formatTime = (seconds) => {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const secs = seconds % 60;
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

  return (
    <div>
     {timer ? <> <p  style={{border:"2px dashed yellow" ,padding:"1.2rem 2rem" ,fontWeight:"600" ,color:"orange"}}>Limited Time Offer : {formatTime(timeLeft)}</p></>:
     <> <p  style={{border:"2px dashed yellow" ,padding:"0rem 1rem" ,fontWeight:"500"}}>Limited Time Offer : {formatTime(timeLeft)}</p></>}
    </div>
  );
};

export default Timer;

const topicsData = [
    {
      "title": "HTML Topics",
      "count":"01",
      "topics": [
        "HTML 5 features",
        "HTML Document Structure",
        "Forms (Input types, attributes, validations)",
        "Tables",
        "Semantic tags, formatting tags and uses",
        "Metatag",
        "Media tags: image, audio, video",
        "SEO using HTML tags",
        "Iframes"
      ],
      "usefulResources": [
        { "title": "HTML MDN Docs", "url": "https://developer.mozilla.org/en-US/docs/Web/HTML" },
        { "title": "W3 Schools", "url": "https://www.w3schools.com/html/" },
        { "title": "HTML Web.Dev", "url": "https://web.dev/learn/html/" },
        { "title": "HTML Best Practises", "url": "https://github.com/hail2u/html-best-practices" },
        { "title": "HTML Best Practises II", "url": "https://www.freecodecamp.org/news/html-best-practices/" },
        { "title": "Increasing SEO using HTML tags", "url": "https://javascript.plainenglish.io/top-10-html-tags-you-need-to-know-for-better-seo-with-code-39add1e7ba6d" },
        { "title": "Semantic Tags and How to use them correctly?", "url": "https://www.semrush.com/blog/semantic-html5-guide/" }
      ]
    },
    {
      "title": "CSS Topics",
      "count":"02",
      "topics": [
        "Box Model",
        "Cascading",
        "Specificity",
        "Flex",
        "display",
        "Grid",
        "Selectors",
        "Pseudo-class Vs Pseudo-elements",
        "Measurement Units (Absolute Vs Relative units, Which one to prefer?)",
        "Positions",
        "Animations and Transitions",
        "z-index",
        "Accessibility",
       " media queries",
       "Background properties",
       "opacity",
       "overlay",
    



      ],
      "usefulResources": [
        { "title": "CSS Web.Dev", "url": "https://web.dev/learn/css/" },
        { "title": "bfe.dev", "url": "https://bigfrontend.dev/css" },
        { "title": "Practise by Patterns", "url": "https://web.dev/patterns/" },
        { "title": "CSS Best practises and Naming conventions", "url": "https://hackernoon.com/best-practice-in-css-organisation-and-naming-conventions-4d103ujy" }
      ]
    },
    {
      "title": "JavaScript Topics",
      "count":"03",
      "topics": [
        "Working of JS",
        "Execution Context",
        "Callstack",
        "Data types (Arrays, Objects, Strings)",
        "let/var/const",
        "Hoisting, Temporal Dead Zone",
        "Functions (Declaration, Expression, Arrow, IIFE)",
        "Call, Apply, Bind",
        "Closure and its uses",
        "Callbacks, Callback Hell",
        "Promises, Async/Await",
        "Event Loop",
       "  Microtasks",
       " Macrotasks"
      ],
      "usefulResources": [
        { "title": "Namaste Javascript Playlist", "url": "https://youtu.be/pN6jk0uUrD8?si=9OSqFUJqgjRp6btb" },
        { "title": "MDN Docs", "url": "https://developer.mozilla.org/en-US/docs/Web/JavaScript" },
        { "title": "Javascript Info", "url": "https://javascript.info/" },
        { "title": "Nisha Singla JS Playlist", "url": "https://youtu.be/Yy9A2rAkOgw?si=C6BeGQ6zLQjiyXFe" }
      ]
    },
    {
      "title": "React.js Topics",
      "count":"04",
      "topics": [
        "file structre",
        "consitionla rendering",
        "Hooks",
        "Outlet component",
        "React Routing",
        "Class based component",
     "   Lifecycle method",
     "Higher order functions",
     "CORS",
     "Controlled and uncontrolled",
"     Mapping",
"Filtering",
        "useState()",
        "useEffect()",
        "useCallback()",
        "useMemo()",
        "useRef()",
        "useReducer()",
        "useContext()",
        "useId()",
        "useLayoutEffect()",
        "Redux",
        "LazyLoading",
      "  Shimmer ui",
      "props /state",
      "functions",
      "API",
      "utils",
      "json"
      ],
      "usefulResources": [
        { "title": "React Docs", "url": "https://react.dev/reference/react" },
        { "title": "React Patterns", "url": "https://reactpatterns.com/" },
        { "title": "React Cheatsheet", "url": "https://devhints.io/react" },
        { "title": "React Handbook", "url": "https://reacthandbook.com/" }
      ]
    },
    {
      "title": "Tailwind CSS Topics",
      "count":"05",
      "topics": [
        "Flex",
        "Grid",
        "Measurement Units (Absolute Vs Relative units, Which one to prefer?)",
        "Positions",
        "Animations and Transitions",
        "z-index",
        "Media queries"
      ],
      "usefulResources": [
        { "title": "Tailwind Docs", "url": "https://tailwindcss.com/" },
        { "title": "CodeCommerce YouTube", "url": "https://www.youtube.com/@codecommerce" }
      ]
    }
  ];
  
  export default topicsData;
  
import React from 'react'
import { Link } from 'react-router-dom';

import './Navbar.scss'
const Navbar = ({handlelogout ,toggleSidebar ,isSidebarOpen ,togglecontactModel ,isLoggedIn}) => {
  
  return (
  
     <div className="navbar_container">
       <div className="navbar">
       <div className="nav-logo"> <Link to='/home' style={{ textDecoration: "none", color: "orange" }}>TheCodingshef</Link></div>
        <div className="navbar-items">
        <li className="nav_list-item"> <Link to="/course" style={{ textDecoration: "none", color: "white" }}>  Courses</Link>
      
           
           </li>
       
          <li className="nav_list-item" onClick={togglecontactModel}>Contact</li>
        
        </div>
      </div>
     </div>
  
  )
}

export default Navbar

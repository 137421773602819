import React from 'react';
import './Card.scss';

const Card = ({title,current,prev,button,course,point1,point2,point3,point4,point5,image ,price ,icon ,type ,card ,link,subheading, subtitile,vedio}) => {
  return (
    <div>
       <div className="card_left-front">
            <div className="card_left-front__card">
              <div className="card-first__section">
               
                { subtitile ? <><iframe 
    width="100%" 
  height="100%"
    src="https://www.youtube.com/embed/YPqnbNoSmFo" 
    title="YouTube video player" 
    frameBorder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
    allowFullScreen>
  </iframe></>: <> <img src={image} alt="" className="image" /></>}
              </div>

              <div className="card-second__section">
                <h3 className="card-second__heading">
                 {title}
                </h3>
              { subtitile && <>  <p style={{marginTop:"1rem",fontWeight:"500"}}>{subheading}</p></>}
              { subtitile && <>  <div className="speak" style={{display:"flex" ,gap:"2rem" ,justifyContent:"left",alignItems:"center"}}><p style={{marginTop:"1rem" ,fontWeight:"500"}}>Language</p><p style={{marginTop:"1rem",border:"2px dashed green" ,padding:"0rem 1rem",fontWeight:"500"}}>hindi</p></div></>}
              
                <div className="card-price__section">
                  <div className="price">
                  {type ? (
  <>
    <div className="current">{current}</div>
    <div className="prev-price">{prev}</div>
  </>
):<div style={{fontSize:"1.5rem"}}>{price}</div> } 
{/* <div className="current">{current}</div>
    <div className="prev-price">{prev}</div> */}
                  </div>
                
                </div>
                 <a href={link} ><button 
     className="card-buy__course-btn ">{button}</button></a>
                <div className="course-include">
                <h3 style={{color: "gray"}}>{course}</h3>
{card && (<><div className="point"><p>{point1}</p></div>

<div className="point">{icon}<p> {point2}</p></div>
<div className="point">{icon}<p>{point3}</p></div>
<div className="point">{icon}<p>{point4}</p></div>
<div className="point">{icon}<p>{point5}</p></div></>)}
                 
         
                </div>

              </div>

            </div>
          </div>
    </div>
  )
}

export default Card



// import React, { useState } from "react";
// import "./Dropdown.scss";
// import topicsData from "../utils/Skill";
// import { FaVideo } from "react-icons/fa";

// const Dropdown = () => {
//   const [openIndex, setOpenIndex] = useState(null); // Track which dropdown is open

//   const toggleDropdown = (index) => {
//     // Toggle the current dropdown or close if already open
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div className="dropdown-container">
//       {topicsData.map((ele, index) => {
//         const isOpen = openIndex === index; // Check if this dropdown is open
//         return (
//           <div key={index} className="dropdown-item">
//             <div className="dropdown-header" onClick={() => toggleDropdown(index)}>
//               <h2>
//                 {index + 1}. {ele.title}
//               </h2>
//               <span className={`arrow ${isOpen ? "rotate" : ""}`}>&#9660;</span>
//             </div>
//             <div
//               className={`dropdown-content ${isOpen ? "open" : ""}`} // Add dynamic class for open/close
//             >
//               <div className="content-item">
//                 <div className="skill_topic">
//                   <FaVideo className="img" />
//                   <h3>Important Guidelines - {ele.title}</h3>
//                 </div>
//                 <div className="topics">
//                   {ele.topics.map((topic, idx) => (
//                     <p key={idx}>{topic}</p>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         );
//       })}
//     </div>
//   );
// };

// export default Dropdown;



import React, { useState } from "react";
import "./Dropdown.scss";
import topicsData from "../utils/Skill";
import { FaVideo } from "react-icons/fa";

const Dropdown = () => {
  const [openIndex, setOpenIndex] = useState(null); // Track which dropdown is open

  const toggleDropdown = (index) => {
    // Toggle the current dropdown or close if already open
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="dropdown-container">
      {topicsData.map((ele, index) => {
        const isOpen = openIndex === index; // Check if this dropdown is open
        return (
          <div key={index} className="dropdown-item">
            <div className="dropdown-header" onClick={() => toggleDropdown(index)}>
              <h2>
                {index + 1}. {ele.title}
              </h2>
              <span className={`arrow ${isOpen ? "rotate" : ""}`}>&#9660;</span>
            </div>
            <div
              className={`dropdown-content ${isOpen ? "open" : ""}`} // Add dynamic class for open/close
            >
              <div className="content-item">
                <div className="skill_topic">
                  <FaVideo className="img" />
                  <h3>Important Guidelines - {ele.title}</h3>
                </div>
                <div className="topics">
                  {ele.topics.map((topic, idx) => (
                    <p key={idx}>{topic}</p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Dropdown;


import React, { useState } from "react";
import "./Main.scss";
import './Homepage.scss'
import { useEffect } from "react";
import { MdOutlineStickyNote2 } from "react-icons/md";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaLinkedin } from "react-icons/fa";
import { FaTv } from "react-icons/fa6";
import { FaConnectdevelop } from "react-icons/fa6";
import { FaGift } from "react-icons/fa";
import { FaMoneyBillWave } from "react-icons/fa";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { FaGithub } from "react-icons/fa";
import { MdOutlineBrandingWatermark } from "react-icons/md";
import { FaAddressCard } from "react-icons/fa";
import ProjectSlider from "../Sliders/ProjectSlider";
import { Link } from "react-router-dom";
import { motion} from 'framer-motion';
import Footer from "../Common/Footer";

import FAQ from "../Common/FAQ";
import Contact from "../Common/Contact";
import Dropdown from "../Common/Dropdown";
import Course from "../Common/Course";
import Navbar from "../Common/Navbar";
export default function Main() {


 useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fadeIn = {
    hidden: { opacity: 0, y: -50 },
   visible: { opacity: 1, y: 0, transition: { duration: 0.8 } },
   };
const [openQuestion, setOpenQuestion] = useState(null);

  const toggleQuestion = (index) => {
  
    if (openQuestion === index) {
      setOpenQuestion(null);
    } else {
      setOpenQuestion(index);
    }
  };

  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isPrivacy, setIsPrivacy] = useState(false);
  const[isHelp ,setIsHelp]=useState(false)
  
  const toggleTermsModal = () => {
    setIsTermsOpen(!isTermsOpen);
   
// setIsHelp(!isHelp)
  };

  const togglePrivacyModel=()=>{
    setIsPrivacy(!isPrivacy)
  }

  const togglecontactModel=()=>{
    setIsHelp(!isHelp)
  }
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);


  const [activeIndex, setActiveIndex] = useState(null); // Track which FAQ is active
 
  const toggleFAQ = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index)); 
  };
  return (
    <div className="container">
      {/* navbar */}

      <Navbar togglecontactModel={togglecontactModel}
     
     />

     {/* Render the Contact Modal */}
     {isHelp && (
       <Contact togglecontactModel={togglecontactModel}/>
     )}


{/* course section */}
      <Course/>

      {/* second section */}

      <motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}  className="learn-section">
        <div className="learn">
        {/* first div */}
            <div className="learn-first__part">
<h1 className="learn-first__heading">What you will learn</h1>
<h3 className="learn-second__heading">Bhout Kuch Sikha Denge 🚀 </h3>
            </div>

{/* second div */}
<div className="learn-grid">
<Dropdown/>
    
</div>
        </div>
      </motion.div>

      {/*  third section perks and benefits */}

      <motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="perks-section">
      <div className="perk-benefits">

      <div className="perks">
            <h1 className="perks-heading">Perks & Benefits</h1>
        </div>

        <div className="perks-grid">

<div className="perks-div">
<div className="perk-icon"><AiOutlineFundProjectionScreen className="officon"/></div>
<div className="perk-text">Project Based Learning</div>
</div>
<div className="perks-div">
<div className="perk-icon"><MdOutlineStickyNote2 className="officon"/></div>
<div className="perk-text">Assignments </div>
</div>
<div className="perks-div">
<div className="perk-icon"><FaTv className="officon"/></div>
<div className="perk-text">Sunday Doubt Classes</div>
</div>
<div className="perks-div">
<div className="perk-icon"><FaConnectdevelop  className="officon"/></div>
<div className="perk-text">Learning Basic to Advance</div>
</div>
<div className="perks-div"><div className="perk-icon"><AiFillSafetyCertificate className="officon"/></div>
<div className="perk-text">Certificates</div></div>
<div className="perks-div"><div className="perk-icon"><FaGift  className="officon"/></div>
<div className="perk-text">Goodies</div></div>
<div className="perks-div"><div className="perk-icon"><FaMoneyBillWave  className="officon"/></div>
<div className="perk-text">Internship </div></div>
<div className="perks-div"><div className="perk-icon"><FaGithub  className="officon"/></div>
<div className="perk-text">Bonus Session on Open Source</div></div>
<div className="perks-div"><div className="perk-icon"><MdOutlineBrandingWatermark  className="officon"/></div>
<div className="perk-text">Bonus Session on Personal Branding</div></div>

<div className="perks-div"><div className="perk-icon"><FaLinkedin  className="officon"/></div>
<div className="perk-text">Bonus Session on Linkedin profile</div></div>

<div className="perks-div"><div className="perk-icon"><FaAddressCard  className="officon" /></div>
<div className="perk-text">Bonus Session on Resume Building</div></div>
        </div>
      </div>

      </motion.div>


      {/*  forth section */}
<motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="project-section">
<div className="project">
<div className="project-heading"><h1 className="heading">Awesome Projects cover in this course </h1></div>


<div className="slide-section">
      {/* <EmployeeSlider/> */}
      <ProjectSlider/>
    </div>
    
</div>
</motion.div>





{/*  Demo vedio section */}

<motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="demo-vedio__section">
  <div className="vedio-section">
    <div className="demo-heading"><h1 className="heading">Sample Lectures </h1></div>

    <div className="demo-vedio">
    <iframe   src="https://www.youtube.com/embed/yl0BFo7fN_c?si=AYgyNs_3K3FOcN3a" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
    <a href="https://www.youtube.com/@TheCodingShef" style={{ textDecoration:"none"}}><div className="button"><button className="youtube-btn">Checkout on Youtube</button></div></a>
    </div>

  </div>
</motion.div>

{/* Last section Faq  section */}

<motion.div variants={fadeIn}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }} className="question-section">
    <div className="question" >
        <div className="question-heading">
            <h1 className="heading" style={{marginTop:"8rem"}}>FAQ Questions</h1>
        </div>

        {/*  questions list */}
  <FAQ/> 
</div>
</motion.div>

{/* <Dropdown/> */}
<Footer/>

    </div>
  );
}


import './App.css';

import Homepage from './Components/Homepage';
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Roadmap from './Common/Roadmap';

// import getDatabase from 'firebase/database'
// import app from './Firebase'
// const db = getDatabase(app)
function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [location]);

  return (
   
      <div className="App">

<Homepage/>

    </div>
    
  );
}

export default App;

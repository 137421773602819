// import React, { useState, useEffect, useRef } from "react";
// import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Arrow icons
// import "./SessionSlider.scss";

// import session2 from "../asset/icons/session2.jpeg";
// import session3 from "../asset/icons/session3.jpeg";
// import session4 from "../asset/icons/session4.jpeg";
// import session5 from "../asset/icons/session5.jpeg";

// const employees = [
//   { id: 2, image: session2 },
//   { id: 3, image: session3 },
//   { id: 4, image: session4 },
//   { id: 5, image: session5 },
//   { id: 6, image: session2 },
//   { id: 7, image: session3 },
//   { id: 8, image: session4 },
//   { id: 9, image: session5 },
// ];

// const SessionSlider = () => {
//   const [currentIndex, setCurrentIndex] = useState(1); // Start at the cloned first slide
//   const sliderRef = useRef();

//   // Create an extended array with clones of the first and last slides
//   const extendedEmployees = [
//     employees[employees.length - 1], // Clone of the last slide
//     ...employees,
//     employees[0], // Clone of the first slide
//   ];

//   const slideWidth = 300; // Adjust this to the width of a single slide

//   // Handle Next Button
//   const handleNext = () => {
//     if (currentIndex < extendedEmployees.length - 1) {
//       setCurrentIndex((prev) => prev + 1);
//     }
//   };

//   // Handle Previous Button
//   const handlePrev = () => {
//     if (currentIndex > 0) {
//       setCurrentIndex((prev) => prev - 1);
//     }
//   };

//   // Handle Infinite Transition (Jump to Real Slides After Clone)
//   useEffect(() => {
//     if (currentIndex === extendedEmployees.length - 1) {
//       // Jump from cloned last slide to real first slide
//       setTimeout(() => {
//         sliderRef.current.style.transition = "none"; // Disable animation
//         setCurrentIndex(1); // Go to the real first slide
//       }, 500); // Match the transition duration
//     } else if (currentIndex === 0) {
//       // Jump from cloned first slide to real last slide
//       setTimeout(() => {
//         sliderRef.current.style.transition = "none"; // Disable animation
//         setCurrentIndex(employees.length); // Go to the real last slide
//       }, 500); // Match the transition duration
//     } else {
//       sliderRef.current.style.transition = "transform 0.5s ease-in-out"; // Enable animation
//     }
//   }, [currentIndex]);

//   return (
//     <div className="slider-container">
//       <div
//         className="slider-row"
//         ref={sliderRef}
//         style={{
//           transform: `translateX(-${currentIndex * slideWidth}px)`,
//           display: "flex",
//         }}
//       >
//         {extendedEmployees.map((employee, index) => (
//           <div key={index} className="employee-cards" style={{ width: `${slideWidth}px` }}>
//             <img
//               className="slide-image"
//               src={employee.image}
//               alt={`Session ${employee.id}`}
//             />
//           </div>
//         ))}
//       </div>

//       {/* Left arrow icon */}
//       <div className="icon-container left" onClick={handlePrev}>
//         <FaChevronLeft className="icon" />
//       </div>

//       {/* Right arrow icon */}
//       <div className="icon-container right" onClick={handleNext}>
//         <FaChevronRight className="icon" />
//       </div>
//     </div>
//   );
// };

// export default SessionSlider;






import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Arrow icons
import "./SessionSlider.scss";

import session2 from "../asset/icons/session2.jpeg";
import session3 from "../asset/icons/session3.jpeg";
import session4 from "../asset/icons/session4.jpeg";
import session5 from "../asset/icons/session5.jpeg";

const employees = [
  { id: 2, image: session2 },
  { id: 3, image: session3 },
  { id: 4, image: session4 },
  { id: 5, image: session5 },
  { id: 6, image: session2 },
  { id: 7, image: session3 },
  { id: 8, image: session4 },
  { id: 9, image: session5 },
];

const SessionSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(1); // Start at the cloned first slide
  const sliderRef = useRef();
  const isDragging = useRef(false);
  const startPos = useRef(0);
  const currentTranslate = useRef(0);
  const prevTranslate = useRef(0);

  // Create an extended array with clones of the first and last slides
  const extendedEmployees = [
    employees[employees.length - 1], // Clone of the last slide
    ...employees,
    employees[0], // Clone of the first slide
  ];

  const slideWidth = 300; // Adjust this to the width of a single slide

  const setSliderPosition = () => {
    sliderRef.current.style.transform = `translateX(${currentTranslate.current}px)`;
  };

  const handleDragStart = (e) => {
    isDragging.current = true;
    startPos.current = e.type.includes("mouse") ? e.pageX : e.touches[0].clientX;
    sliderRef.current.style.transition = "none";
  };

  const handleDragMove = (e) => {
    if (!isDragging.current) return;
    const currentPosition = e.type.includes("mouse")
      ? e.pageX
      : e.touches[0].clientX;
    const diff = currentPosition - startPos.current;
    currentTranslate.current = prevTranslate.current + diff;
    setSliderPosition();
  };

  const handleDragEnd = () => {
    isDragging.current = false;
    const movedBy = currentTranslate.current - prevTranslate.current;

    // Snap to the closest slide
    if (movedBy < -50 && currentIndex < extendedEmployees.length - 1) {
      setCurrentIndex((prev) => prev + 1);
    } else if (movedBy > 50 && currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
    } else {
      currentTranslate.current = -currentIndex * slideWidth;
      setSliderPosition();
    }
    prevTranslate.current = currentTranslate.current;
  };

  // Handle Infinite Transition (Jump to Real Slides After Clone)
  useEffect(() => {
    if (currentIndex === extendedEmployees.length - 1) {
      // Jump from cloned last slide to real first slide
      setTimeout(() => {
        sliderRef.current.style.transition = "none";
        setCurrentIndex(1); // Go to the real first slide
        prevTranslate.current = -slideWidth;
        currentTranslate.current = -slideWidth;
        setSliderPosition();
      }, 500); // Match the transition duration
    } else if (currentIndex === 0) {
      // Jump from cloned first slide to real last slide
      setTimeout(() => {
        sliderRef.current.style.transition = "none";
        setCurrentIndex(employees.length); // Go to the real last slide
        prevTranslate.current = -employees.length * slideWidth;
        currentTranslate.current = -employees.length * slideWidth;
        setSliderPosition();
      }, 500); // Match the transition duration
    } else {
      sliderRef.current.style.transition = "transform 0.5s ease-in-out";
      prevTranslate.current = -currentIndex * slideWidth;
      currentTranslate.current = -currentIndex * slideWidth;
    }
  }, [currentIndex]);

  return (
    <div
      className="slider-container"
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
      onMouseMove={handleDragMove}
      onTouchMove={handleDragMove}
      onMouseUp={handleDragEnd}
      onMouseLeave={() => isDragging.current && handleDragEnd()}
      onTouchEnd={handleDragEnd}
    >
      <div
        className="slider-row"
        ref={sliderRef}
        style={{
          transform: `translateX(-${currentIndex * slideWidth}px)`,
          display: "flex",
        }}
      >
        {extendedEmployees.map((employee, index) => (
          <div key={index} className="employee-cards" style={{ width: `${slideWidth}px` }}>
            <img
              className="slide-image"
              src={employee.image}
              alt={`Session ${employee.id}`}
            />
          </div>
        ))}
      </div>

      {/* Left arrow icon */}
      <div className="icon-container left" onClick={() => setCurrentIndex(currentIndex - 1)}>
        <FaChevronLeft className="icon" />
      </div>

      {/* Right arrow icon */}
      <div className="icon-container right" onClick={() => setCurrentIndex(currentIndex + 1)}>
        <FaChevronRight className="icon" />
      </div>
    </div>
  );
};

export default SessionSlider;




import React from 'react'
import { useEffect } from 'react'
import './Androidpage.scss'
import { GiCheckMark } from "react-icons/gi";
import Navbar from '../Common/Navbar'
import { SiTicktick } from "react-icons/si";
import { FcClock } from "react-icons/fc";
import { FcCalendar } from "react-icons/fc";

import './Paidworkshop.scss';
import { motion } from 'framer-motion';
import Banner from '../asset/icons/Banner.jpeg'
import Footer from '../Common/Footer'
import Card from '../Common/Card'
import anfiya2 from '../asset/icons/anfiya2.jpg'
import page from '../asset/icons/page.png'
import Timer from '../Common/Timer';
const Paidworkshop = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
const cardData = {
  title: " Let's Build Stunning Portfolio",
subheading:"Build Your React Portfolio: Craft Stunning, Responsive Websites with Ease!",
  button:"Book yor Seat",

  current:"₹99",
 prev:"1000",
  type: true,
  subtitile:true,
  card:false,
  image: anfiya2,
  link:"https://pages.razorpay.com/pl_Pb6jSx2IqTgy1B/view",
  vedio:"https://www.youtube.com/embed/YPqnbNoSmFo",
  vedioboolean:true

};

const timer="true";



const workspecial=[
  { 
    content:"Master media queries to create responsive designs."
  },
  {
    content:"Dive deep into React hooks and their practical use cases."
  },
  {
    content:"Learn to create a professional README file for GitHub"
  },
  {
    content:"Deploy your project seamlessly on Netlify."
  },
  {
    content:"Get your questions answered live during the session!"
  }
]




  return (
    <div className='workshoppage-container'>
    <Navbar/>
      <motion.div
        className="commonblue "
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1 }}>
      <div className="workshoppage2">

<div className="workshoppagelive2">
<motion.div
              className="left-workshop"
              initial={{ x: -100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}><h1 className='left-first-heading'>Let’s Build a Stunning Portfolio with React <br /> </h1>

<h2 className="left-para2">Stand Out, Get Noticed, and Land Your Dream Role! </h2>

<br />


{/*  vedio */}
<div className="vedeo">
{/* <video className="workshop-video" controls width="90%">
    <source src="https://youtube.com/shorts/YPqnbNoSmFo?si=iUzOMw-bDwtwpb8I" type="video/mp4" />
    Your browser does not support the video tag.
  </video> */}
  {/* <div className="banner-card">
    <img src={workshop} alt="" className='banner-card-img'  />
  </div> */}

  <div className="workshop-video">
  <iframe 
    width="90%" 
    height="315" 
    src="https://www.youtube.com/embed/YPqnbNoSmFo" 
    title="YouTube video player" 
    frameborder="0" 
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
    allowfullscreen>
  </iframe>
</div>

  <div className="language"><p className="lang">Language</p> <p className='hindi'>Hindi</p></div>
  <div className="price-pay">
  <h2 className='pay'>₹99</h2>
  <h2 className='pay2'>₹1000</h2> </div>

  <div className="clock-timer"><p className="clock"><Timer/></p></div>
<a href="https://pages.razorpay.com/pl_Pb6jSx2IqTgy1B/view"><div className="cost-pay"><button className=" video-btn">Enroll now</button></div></a>
</div>
<p className="left-para-section2" >Is your resume not shortlisting? Lets Start with a standout portfolio—because your work should speak louder than words!</p>


<div className="workshop-item">
<div className="workshop-shedule">
<li className="workshop-list"><FcCalendar  className='workshop-icon'/>11 January (Saturday)</li>
<li className="workshop-list"><FcClock className='workshop-icon' />5:00 PM - 8:00 PM</li></div>
<div className="workshop-timer">
<div className="clocktime"><p className="clock2"><Timer timer={timer}/></p></div>

</div>

</div>
</motion.div>

    <motion.div
              className="right-workshop"
              initial={{ x: 100, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.8 }}>
    <Card  title={cardData.title}
    subheading={cardData.subheading}
    current={cardData.current}
          button={cardData.button}
          course={cardData.course}
          subtitile={cardData. subtitile}
          image={cardData.image}
          type={cardData.type}
          card={cardData.card}
          link={cardData.link}
          vedio={cardData.vedio}
          vedioboolean={cardData.vedioboolean}
           prev={cardData.prev}
          />

    </motion.div>
    
</div>
</div>
      </motion.div>

      <div  className="about-workshop__section" >
      
        <div className="about-workshop">
          <div className="about-heading"><h1 className="heading">What you'll learn in just 2 Hours</h1></div>
          <div className="workshop-content">
          

          <motion.div
            className="html2 "
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Html basics</h3>
              <li className="content-list"> Cover Elements, Tags, and Semantics.</li>
            </motion.div>
            <motion.div
            className="html2"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Css basics</h3>
              <li className="content-list"> Cover Selectors, Flexbox, and Grid.</li>
            </motion.div>
            <motion.div
            className="html2"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.7, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>React Fundamentals: </h3>
              <li className="content-list"> Dive into React concepts such as components, hooks, and state management ,Redux.</li>
            </motion.div>
            <motion.div className="html2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Project Structure </h3>
            <li className="content-list"> Learn to structure a React project with clear folder hierarchy (e.g., components, assets, pages, styles).</li>
<li className="content-list">Utilize src folder efficiently.</li>
            </motion.div>
            <motion.div className="html2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.9, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Component-based </h3>
            <li className="content-list"> Break the UI into reusable components like Header, Footer, About, Projects, and Contact.
</li>

            </motion.div>

            <motion.div className="html2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.0, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Routing</h3>
            <li className="content-list"> Use React Router for creating a multi-page portfolio.
           </li>
           <li className="content-list"> E.g., Home, About, Projects, and Contact pages.</li>

            </motion.div>
            <motion.div className="html2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.1, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Responsive Design:</h3>
            <li className="content-list"> Learn  SCSS to create websites that look amazing on all devices, from desktops to smartphones.</li>

            </motion.div>
            <motion.div className="javascript2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.2, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Animations:</h3>
            <li className="content-list">Use libraries like Framer Motion, React Spring, or GSAP to add smooth animations and transitions.</li>
            </motion.div>
            <motion.div className="react-content2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.3, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Hands-on Coding: </h3>
            <li className="content-list">Work on real-world projects to gain practical experience
</li>
    

            </motion.div>
            <motion.div className="react-content2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.4, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/> Theme Toggle</h3>
            <li className="content-list">Add light/dark mode functionality using CSS variables or Context API.

</li>
    

            </motion.div>
            <motion.div className="react-content2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.5, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Project Deployment: </h3>
            <li className="content-list"> Learn how to deploy your website on Netlify and make it live for the world to see.
</li>
    

            </motion.div>
           
            <motion.div className="react-content2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.6, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Showcasing Projects</h3>
            <li className="content-list"> Use cards or carousels to display your projects dynamically.
</li>
 <li className="content-list"> Include links to live demos and GitHub repositories.
</li>
    

            </motion.div>


            <motion.div className="react-content2" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.7, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Dynamic Content</h3>
            <li className="content-list"> Store information (e.g., projects, skills, experiences) in JSON or external databases to make updates easier.
</li>
 

            </motion.div>
           
          </div>
        </div>
       </div>






      
      {/*  about section
       */}




       <div className="about-workshop__section">
       <div className="left-line"></div> 
        <div className="about-workshop">
          <div className="about-heading"><h1 className="heading">Why Should You Attend This Workshop?</h1></div>


          <div className="workshop-content">
            <motion.div className="html" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.8, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading" ><SiTicktick  className='markstic'/>Live Sessions: </h3>
              <li className="content-list2">  Live coding experience.</li>
            </motion.div>
            <motion.div className="css" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1.9, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Interactive Learning:</h3>
            <li className="content-list2">Ask questions, get feedback, and engage with fellow learners.</li>

            </motion.div>
            <motion.div className="javascript" initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 2.0, ease: "easeOut" }}
            whileHover={{ scale: 1.05, boxShadow: "0px 8px 20px rgba(255, 255, 0, 0.8)" }}>
            <h3 className="workshop-content__heading"><SiTicktick  className='markstic'/>Portfolio Project</h3>
            <li className="content-list2"> Add a fully functional responsive website to your developer portfolio.</li>
            </motion.div>
        
           
          </div>
        </div>
       </div>




{/* / benefits */}

<div className="attend-section2">
        <div className="attend">
          <div className="attend-heading"><h1 className="heading">What Makes This Workshop Special ? </h1></div>
          <div className="android-section3">
      {
        workspecial.map((ele)=>{
          return(
            <>
            <motion.div
          className="android-card2"
        
          whileHover={{
            scale: 1.05, // Slightly enlarge on hover
            y: -10, // Move the card up on hover
            boxShadow: "0px 8px 12px rgba(0, 0, 0, 0.2)", // Add a shadow effect
          }}
          transition={{
            duration: 0.3, // Duration of the animation
            ease: "easeInOut", // Easing function
          }}
        >
          <p className="card-para"><GiCheckMark className='check-icon'/>  {ele.content}</p>
          
        </motion.div>
        
    
            </>
          )
        })
      }
        
    </div>
        
        </div>
       </div>

{/*  Reviews */}


<div className="reviews-section">
  <div className="review-container">
 <div className="review">
  <h1 className="review-heading">Reviews</h1>
 </div>
 <div className="review-img">
  <div className="image-section">
    <img src={page} className='page-image' alt="" />
  </div>
 </div>
  </div>
</div>

       {/*  reserve spot */}

      <div className="reserve-spot2">
      <div className="reserve-section">
        <div className="reserve">
          <div className="attend-heading"><h1 className="reserve-heading2">Buckle Up and Build a Stunning React Portfolio <br />Your Dream Job Awaits!"</h1></div>

          <div className="reserve-page2">
          <h3 className="workshop-content__heading3">Limited seats available ,Don’t miss this chance to build a stunning React portfolio <br />that showcases your skills and sets you apart from the crowd!"</h3>
           
         
            </div>
        </div>
       </div>

      </div>




      
      

      {/* Footer section */}
      <Footer/>

    </div>
  )
}

export default Paidworkshop;
